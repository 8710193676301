<!-- =========================================================================================
    File Name: DashboardEcommerce.vue
    Description: Dashboard - Ecommerce
    ----------------------------------------------------------------------------------------
    Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
      Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <div>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <!-- <h4 class="header-title">Advanced Data Table</h4> -->
            <p class="text-muted font-13 mb-0"></p>
            <!-- Table -->
            <div>
              <b-card-code>
                <validation-observer ref="simpleRules">
                  <form @submit.prevent="submitForm">
                    <b-row>
                      <b-col md="6">
                        <b-form-group>
                          <label>Project Name</label>
                          <v-select
                            v-model="projectname"
                            label="projectname"
                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            placeholder="None"
                            :options="projectOptions"
                          />
                        </b-form-group>
                      </b-col>
                    </b-row>
                    <div class="d-flex justify-content-end">
                      <b-button variant="primary" class="ml-2" @click="searchData">
                        <feather-icon icon="SearchIcon" class="mr-50" />
                        <span class="align-middle">Search</span>
                      </b-button>
                    </div>
                  </form>
                </validation-observer>
              </b-card-code>
              <b-card-code>
                <report-table :data="data" :columns="fields">
                  <template #customField="{ props }">
                    <div v-if="props.column.field == 'pie'">
                      <div class="d-flex align-items-center text-center">
                        <vue-apex-charts
                          height="120"
                          type="donut"
                          :options="earningsChart.chartOptions"
                          :series="setSeries(props.row)"
                        />
                      </div>
                    </div>
                  </template>
                  <template #customPrint="{ data, item }">
                    <div v-if="data.field == 'pie'" style="width: 200px">
                      <div class="d-flex align-items-center text-center">
                        <vue-apex-charts
                          height="120"
                          type="donut"
                          :options="earningsChart.chartOptions"
                          :series="setSeries(item)"
                        />
                      </div>
                    </div>
                  </template>
                  <template #customColumn="{ props }">
                    <div v-if="props.column.field == 'pie'" style="width: 200px">
                      <span>{{ props.column.label }}</span>
                    </div>
                  </template>
                </report-table>
              </b-card-code>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ReportTable from "../../../components/ReportTable.vue";
import axios from "@/components/axios";
import Table from "@/components/Table.vue";
import BCardCode from "@core/components/b-card-code";
import flatPickr from "vue-flatpickr-component";
import GoodTableColumnSearch from "../../../components/GoodTableColumnSearch.vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import Form from "@/components/form/Form.vue";
import moment from "moment";
import {
  BFormInput,
  BFormGroup,
  BForm,
  BRow,
  BCol,
  BButton,
  BFormTextarea,
  BFormDatepicker,
  BImgLazy,
} from "bootstrap-vue";
import vSelect from "vue-select";
import VueApexCharts from "vue-apexcharts";

const $Vacant = "#28c76f";
const $Booked = "#00cfe8";
const $Sold = "#eded3c";
const $Hold = "#ff9f43";
const $Registered = "#ea5455";
export default {
  components: {
    ReportTable,
    VueApexCharts,
    BFormGroup,
    BForm,
    BRow,
    BCol,
    BButton,
    vSelect,
    flatPickr,
    ValidationProvider,
    ValidationObserver,
    BCardCode,
    Form,
    GoodTableColumnSearch,
  },
  data() {
    return {
      result: "",
      data: "",
      projectname: "",
      projectOptions: [],
      baseApi: process.env.VUE_APP_APIENDPOINT,
      accessToken: localStorage.getItem("accessToken"),
      fields: [
        {
          field: "pie",
          label: "Pie Chart",
        },
        {
          field: "projectname",
          label: "Project",
        },
        {
          field: "pricepersqyd",
          label: "Price (per sq. yard)",
        },
        {
          field: "projectvalue",
          label: "Project Value (in %)",
        },
        {
          field: "developmentplanvalue",
          label: "Development Plan Value(in %)",
        },
        {
          field: "cornerphase",
          label: "Corner Phase",
        },
        {
          field: "gardenphase",
          label: "Garden Phase",
        },
        {
          field: "plottedareainsqmtr",
          label: "Project Area(In Sq. MTR)",
        },
        {
          field: "plottedareainsqyd",
          label: "Project Area(In Sq. Yard)",
        },
        {
          field: "projectsuperbuildupareasqmtr",
          label: "Project Super Build Up Area(In Sq. MTR)",
        },
        {
          field: "projectsuperbuildareasqyd",
          label: "Project Super Build Up Area(In Sq. Yard)",
        },
        {
          field: "totalprojectareamtr",
          label: "Total Project Area(In Sq. MTR)",
        },
        {
          field: "totalprojectareayd",
          label: "Total Project Area(In Sq. Yard)",
        },
        {
          field: "company",
          label: "Company Name",
        },
        {
          field: "oldsurveyno",
          label: "Old Survey No",
        },
        {
          field: "newsurveyno",
          label: "New Survey No",
        },
        {
          field: "accountno",
          label: "Account No / Khata No",
        },
        {
          field: "naorderno",
          label: "NA Order No",
        },
        {
          field: "naorderdate",
          label: "NA Order Date",
        },
        {
          field: "projecttype",
          label: "Project Type",
        },
        {
          field: "rerano",
          label: "RERA No",
        },
        {
          field: "opno",
          label: "OPFO No",
        },
        // {
        //   field: "fpno",
        //   label: "FP No",
        // },
        {
          field: "village",
          label: "Village",
        },
        {
          field: "taluka",
          label: "Taluka",
        },
        {
          field: "pincode",
          label: "Pin Code",
        },
        {
          field: "city",
          label: "City",
        },
        {
          field: "state",
          label: "State",
        },
        {
          field: "projectstatus",
          label: "Project Status",
        },
        {
          field: "Booked",
          label: "Booked Register",
        },
        {
          field: "Vacant",
          label: "Vacant Plot",
        },
        {
          field: "Registered",
          label: "Registered Plot",
        },
        {
          field: "Sold",
          label: "Sold Plot",
        },
        {
          field: "Hold",
          label: "Hold Plot",
        },
      ],
      earningsChart: {
        chartOptions: {
          chart: {
            type: "donut",
            toolbar: {
              show: false,
            },
          },
          dataLabels: {
            enabled: false,
          },
          legend: { show: false },
          comparedResult: [2, -3],
          labels: ["Vacant", "Booked", "Sold", "Hold", "Registered"],
          colors: [$Vacant, $Booked, $Sold, $Hold, $Registered],
          stroke: { width: 0 },
          grid: {
            padding: {
              right: -20,
              bottom: -8,
              left: -20,
            },
          },
          plotOptions: {
            pie: {
              startAngle: 0,
              donut: {
                labels: {
                  show: true,
                  name: {
                    offsetY: 15,
                  },
                  value: {
                    offsetY: -15,
                    formatter(val) {
                      // eslint-disable-next-line radix
                      return `${parseInt(val)}`;
                    },
                  },
                  total: {
                    show: true,
                    offsetY: 15,
                    label: "Total",
                  },
                },
              },
            },
          },
          responsive: [
            {
              breakpoint: 1325,
              options: {
                chart: {
                  height: 120,
                },
              },
            },
            {
              breakpoint: 1200,
              options: {
                chart: {
                  height: 120,
                },
              },
            },
            {
              breakpoint: 1045,
              options: {
                chart: {
                  height: 100,
                },
              },
            },
            {
              breakpoint: 992,
              options: {
                chart: {
                  height: 120,
                },
              },
            },
          ],
        },
      },
    };
  },
  mounted() {
    this.getProjectname();
  },
  computed: {
    setSeries() {
      return (row) => {
        return [row.Vacant, row.Booked, row.Sold, row.Hold, row.Registered];
      };
    },
  },
  methods: {
    async searchData() {
      const data = {
        project_id: this.projectname ? this.projectname.id : "",
      };
      await axios({
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
        data: JSON.stringify(data),
        url: `${this.baseApi}/projectreport`,
      }).then((response) => {
        this.data = response.data.data;
        this.data.map((item) => {
          item.opno = item.opfpno && item.opfpno.opno;
          item.fpno = item.opfpno && item.opfpno.fpno;
          item.village = item.village && item.village.village_name;
          item.naorderdate = item.naorderdate == "0000-00-00" ? "No" : item.naorderdate;
          item.Booked = item.Booked ? item.Booked : 0;
          item.Vacant = item.Vacant ? item.Vacant : 0;
          item.Registered = item.Registered ? item.Registered : 0;
          item.Sold = item.Sold ? item.Sold : 0;
          item.Hold = item.Hold ? item.Hold : 0;
          item.company = item.company ? item.company.name : "";
        });
      });
    },
    async getProjectname() {
      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
        url: `${this.baseApi}/getProject`,
      };
      await axios(requestOptions)
        .then((response) => {
          this.projectOptions = response.data.data;
        })
        .catch((error) => console.log(error, "error"));
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";
</style>
